import React, { FC } from 'react';
import './SectionTitle.scss';
import { TSectionTitleProps } from '../../../types/types';

const SectionTitle: FC<TSectionTitleProps> = (props) => {
    return (
        <h2
            data-testid="section-title"
            className={`title title--${props.type} title title--${props.subtype}`}
        >
            {props.title}
        </h2>
    );
};

export default SectionTitle;
