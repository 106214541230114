import React from 'react';

export const useIsSsr = () => {
    const [isSsr, setIsSsr] = React.useState(true);

    React.useEffect(() => {
        setIsSsr(false);
    }, []);

    return isSsr;
};
