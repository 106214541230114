import React from 'react';
import * as Scroll from 'react-scroll';

import { useIsSsr } from '../../../hooks/useIsSsr';

import './FixedButton.scss';

const FixedButton = () => {
    const [showButton, setShowButton] = React.useState<boolean>(false);
    const isSsr = useIsSsr();

    const scrollToTop = () => {
        const scroll = Scroll.animateScroll;
        scroll.scrollToTop({ duration: 500 });
    };

    React.useEffect(() => {
        if (!isSsr) {
            window.scrollY > 300 ? setShowButton(true) : setShowButton(false);

            const handleScrollButtonVisibility = () => {
                window.scrollY > 300 ? setShowButton(true) : setShowButton(false);
            };

            window.addEventListener('scroll', handleScrollButtonVisibility);

            return () => {
                window.removeEventListener('scroll', handleScrollButtonVisibility);
            };
        }
    }, [isSsr]);

    return showButton ? (
        <button onClick={scrollToTop} className="fixed-button">
            <i className="fixed-button-arrow__up" />
        </button>
    ) : null;
};

export default FixedButton;
